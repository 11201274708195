import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import {
  ErrorUtil,
  IConnection,
  IConnectionType,
  ICreateConnectionRequestDto,
  IntegrationType,
  IOAuthClientCredential,
  IOAuthCredential,
} from '@site-mate/sitemate-flowsite-shared';

import { FlowsiteApiHttpClient } from 'app/core/http/clients/api.client';
import { IntegrationService } from 'app/core/services/integration.service';
import { WorkspaceService } from 'app/core/services/workspace.service';

import { IExternalAuthState } from './external-auth.service';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
  private readonly api = inject(FlowsiteApiHttpClient);
  private readonly integrationService = inject(IntegrationService);
  private readonly workspaceService = inject(WorkspaceService);

  // Cache for connection observables for each workspace and integration type
  private connectionsCache: Map<string, Observable<IConnection[]>> = new Map();

  /**
   * Query all connections for the current workspace
   *
   * @returns all connections for the current workspace
   */
  getConnections(filters?: { excludeIntegrationTypes: IntegrationType[] }) {
    const workspaceId = this.workspaceService.currentWorkspaceId();
    return this.fetchConnectionsWithoutTypes(workspaceId, filters?.excludeIntegrationTypes ?? []);
  }

  /**
   * Query all connections with a specific integration type
   *
   * @param integrationType the integration filter to use
   * @returns all connections for a specific integration type
   */
  getConnectionsWithType(integrationType: IntegrationType) {
    const workspaceId = this.workspaceService.currentWorkspaceId();
    const cacheKey = `${workspaceId}_${integrationType}`;

    const cachedConnections$ = this.connectionsCache.get(cacheKey);
    if (cachedConnections$) {
      return cachedConnections$;
    }

    return this.fetchConnectionsWithType(workspaceId, integrationType).pipe(
      tap((connections) => {
        this.connectionsCache.set(cacheKey, of(connections));
      }),
    );
  }

  /**
   * Query the first connection with a specific integration type
   *
   * @param integrationType the integration filter to use
   * @returns a connection for a specific integration type, can be undefined if no connection is found
   */
  getFirstConnectionWithType(integrationType: IntegrationType) {
    return this.getConnectionsWithType(integrationType).pipe(
      map((connections) => (connections.length ? connections[0] : undefined)),
    );
  }

  fetchOpenApiConnection(workspaceId: string): Observable<IConnection> {
    return this.api.get<IConnection>(`/workspaces/${workspaceId}/connections/sitemate-open-api`);
  }

  createConnection(workspaceId: string, connection: ICreateConnectionRequestDto): Observable<IConnection> {
    return this.api.post<IConnection>(`/workspaces/${workspaceId}/connections`, connection);
  }

  refreshConnection(credential: IOAuthCredential, state: IExternalAuthState) {
    const { connectionId: connectionPath } = state as Required<IExternalAuthState>;
    const [, workspaceId, connectionId] = connectionPath.split('/');

    const connectionParams: Partial<IConnection> = {
      type: IConnectionType.OAuth,
      credential,
    };

    return this.api
      .patch<IConnection>(
        `/workspaces/${workspaceId}/connections/${connectionId}/reconnect`,
        connectionParams,
      )
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            const errorMessage = error.message ?? `Request failed with status code ${error.status}`;
            throw new Error(errorMessage);
          }
          const errorMessage = ErrorUtil.handleErrorMessage(
            error,
            `reconnection failed for connectionId ${connectionPath}`,
          );
          throw new Error(errorMessage);
        }),
      );
  }

  deleteConnection(workspaceId: string, connectionPath: string) {
    const connectionId = connectionPath.split('/').pop();

    return this.api.delete(`/workspaces/${workspaceId}/connections/${connectionId}`);
  }

  createOrUpdateOpenApiConnection(workspaceId: string): Observable<IOAuthClientCredential> {
    return this.api.post<IOAuthClientCredential>(
      `/workspaces/${workspaceId}/sitemate-open-api/entities`,
      undefined,
    );
  }

  private fetchConnectionsWithType(
    workspaceId: string,
    integrationType: IntegrationType,
  ): Observable<IConnection[]> {
    return this.integrationService.getIntegration(integrationType).pipe(
      switchMap((integration) => {
        const params = new HttpParams().set('integrationId', integration._id);
        return this.api.get<IConnection[]>(`/workspaces/${workspaceId}/connections`, { params });
      }),
    );
  }

  private fetchConnectionsWithoutTypes(
    workspaceId: string,
    excludeIntegrationTypes: IntegrationType[],
  ): Observable<IConnection[]> {
    return this.integrationService.getIntegrationsMap().pipe(
      switchMap((integrationsMap) => {
        const integrationIds = Array.from(integrationsMap.values())
          .filter((integration) => integration && excludeIntegrationTypes.includes(integration.type))
          .map((integration) => integration?._id);

        const params = new HttpParams().set('excludeIntegrationIds', integrationIds.join(','));
        return this.api.get<IConnection[]>(`/workspaces/${workspaceId}/connections`, { params });
      }),
    );
  }
}
